import { Str } from '@supercharge/strings';
import VatRate from '@/app/invoicing/models/VatRate';
import TaskCollection from '@/app/task/collections/TaskCollection';
import BaseModel from '~~/app/base/models/BaseModel';
import { ModelType } from '~~/app/base/schemas/BaseSchema';
import Task from '@/app/task/models/Task';
import InvoiceCollection from '~/app/invoicing/collections/InvoiceCollection';
import Invoice from '~/app/invoicing/models/Invoice';

export default class Contact extends BaseModel {
    type: ModelType = ModelType.CONTACTS;
    static type: ModelType = ModelType.CONTACTS;
    prefix = 'contact:contact';
    static prefix = 'contact:contact';

    get relationshipsMap(): Record<string, ModelType> {
        return {
            tasks: ModelType.TASKS,
            invoices: ModelType.INVOICES,
        };
    }

    relationshipsMapped(): void {
        if (this.relationships.tasks && this.relationships.tasks.data && typeof this.relationships.tasks.data.isModelOrCollection === 'function') {
            this.relationships.tasks.data.map((task: Task) => task.setRelationship('contact', this.cloneWithoutRelationships(ModelType.CONTACTS)));
            this.relationships.tasks.data.setItemShouldBeInCollectionCallback((task: Task) => task.contact?.getId() === this.getId());
        }
    }

    get tasks(): TaskCollection | null {
        return this.relationships?.tasks?.data;
    }

    get invoices(): InvoiceCollection | null {
        return this.relationships?.invoices?.data;
    }

    getLatestInvoices(): Invoice[] | null {
        return this.invoices?.sortByMoment('trueDate', 'desc').slice(0, 5) ?? null;
    }

    get isProfessional(): boolean {
        return (this.vatNumber !== null && this.vatNumber !== undefined && this.vatNumber !== '') || (this.siret !== null && this.siret !== undefined && this.siret !== '');
    }

    get contactType(): string {
        return this.attributes?.contactType;
    }

    get displayName(): string {
        if (!this.companyName) {
            return this.name;
        }

        if (!this.name) {
            return this.companyName;
        }

        return `${this.companyName} (${this.name})`;
    }

    get name(): string {
        return Str(`${this.firstName || ''} ${this.lastName || ''}`)
            .trim()
            .toString();
    }

    get firstName(): string {
        return this.attributes?.firstName;
    }

    get lastName(): string {
        return this.attributes?.lastName;
    }

    get companyName(): string | null {
        return this.attributes?.companyName;
    }

    get email(): string {
        return this.attributes?.email;
    }

    get phone(): string {
        return this.attributes?.phone;
    }

    get vatNumber(): string {
        return this.attributes?.vatNumber;
    }

    get siret(): string {
        return this.attributes?.siret;
    }

    get identificationNumber(): string {
        return this.attributes?.identificationNumber;
    }

    get honorificPrefix(): string {
        return this.attributes?.honorificPrefix;
    }

    get comments(): string {
        return this.attributes?.comments;
    }

    get options(): Record<string, any> {
        return this.attributes?.options;
    }

    get address(): Address | null {
        return this.attributes?.address;
    }

    get billingAddress(): Address | null {
        return this.attributes?.billingAddress;
    }

    get locale(): string {
        return this.attributes?.locale;
    }

    get defaultVatRate(): VatRate | null {
        return this.relationships?.defaultVatRate?.data;
    }
}
