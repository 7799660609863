import Invoice from '~~/app/invoicing/models/Invoice';

export default class InvoiceRoute {
    private documentType: string;

    constructor(documentType: string) {
        this.documentType = documentType;
    }

    base(): string {
        return `/invoicing/${this.documentType}s`;
    }

    index(): string {
        return this.base();
    }

    create(): string {
        return `${this.base()}/create`;
    }

    edit(id: string): string {
        return `${this.base()}/${id}/edit`;
    }
}
